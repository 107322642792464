function cached(fn) {
  const cache = Object.create(null);
  return function cachedFn(str) {
    const hit = cache[str];
    return hit || (cache[str] = fn(str));
  };
}

const regex = /-(\w)/g;
const camelize = cached(str => str.replace(regex, (_, c) => c.toUpperCase()));

let guid = Date.now();
export function uniqueId(prefix = "") {
  return `${prefix ? `${prefix}_` : ""}${(guid++).toString(36).toLowerCase()}`;
}
export { camelize };
